exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-preview-[document]-js": () => import("./../../../src/pages/preview/[document].js" /* webpackChunkName: "component---src-pages-preview-[document]-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}


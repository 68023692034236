import React, { useEffect } from 'react';
import Layout from '@components/layout';
import SimplePortableText from '@components/sanity/simplePortableText';
import { Link } from 'gatsby';
import ModuleWrapper from '@components/pageModules/moduleWrapper';

const SuccessPage = () => {
  const [sessionData, setSessionData] = React.useState(null);

  useEffect(() => {
    // Get stripe session id from url
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    // Fetch the session
    fetch(`/.netlify/functions/stripe-session?sessionId=${sessionId}`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        setSessionData(data);
      });
  }, []);

  return (
    <Layout dark>
      <ModuleWrapper className="px-gutter mt-[88px] py-16 lg:py-24">
        <div className="grid gap-6 max-w-6xl mx-auto bg-white border border-grey-200 rounded-xl py-10 px-6 lg:px-10">
          <h4>Payment successful.</h4>
          {sessionData?.metadata?.formConfirmationText ? (
            <SimplePortableText
              text={JSON.parse(sessionData.metadata.formConfirmationText)}
            />
          ) : null}
          <Link
            href="/"
            className="bg-grey text-white px-6 py-3 rounded-lg ml-auto"
          >
            Back to home
          </Link>
        </div>
      </ModuleWrapper>
    </Layout>
  );
};

export default SuccessPage;

import React, { useCallback, useState, useEffect } from 'react';
import { Router } from '@reach/router';

import { useLoads } from 'react-loads';

import { sanityClient } from '../../lib/sanityClient';

import HomePage from '../../pages/index';
import ModularTemplate from '../../templates/page';
import PostTemplate from '../../templates/post';

const PAGE_LINK_FRAGMENT = `
  ...,
  page->{
    _type,
    slug {
      current
    }
  }
`;

const CTA_FRAGMENT = `
  ...,
  _type == 'pageLink' => {
    ${PAGE_LINK_FRAGMENT}
  },
  _type == 'pageAnchorLink' => {
    ${PAGE_LINK_FRAGMENT}
  },
`;

const PORTABLE_TEXT_FRAGMENT = `
  ...,
  _type == 'ctaLink' => {
    ${PAGE_LINK_FRAGMENT}
  }
`;

const PreviewPage = ({ document }) => {
  const queryDraft = `*[_id == "${document}"]{
    ...,
    modules {
      modules[] {
        ...,
        _type == 'accordions' => {
          ...,
          sections[] {
            ...,
            content[] {
              ${PORTABLE_TEXT_FRAGMENT}
            }
          }
        },
        _type == 'bioGrid' => {
          ...,
          items[]->{
            ...,
          }
        },
        _type == 'cardCarousel' => {
          ...,
          items[] {
            ...,
            cta[] {
              ${CTA_FRAGMENT}
            }
          }
        },
        _type == 'careersFeed' => {
          ...,
          items[]->{
            ...
          }
        },
        _type == 'contactDetails' => {
          ...,
          details[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'cta' => {
          ...,
          cta[] {
            ${CTA_FRAGMENT}
          },
          text[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'featureBlock' => {
          ...,
          cta[] {
            ${CTA_FRAGMENT}
          }
        },
        _type == 'imageTextBlock' => {
          ...,
          text[] {
            ${PORTABLE_TEXT_FRAGMENT}
          },
        },
        _type == 'introBlock' => {
          ...,
          text[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'navigationBanner' => {
          ...,
          navigationItems[] {
            ...,
            link[] {
              ${CTA_FRAGMENT}
            }
          }
        },
        _type == 'newsCarousel' => {
          ...,
          selectedPosts[]->{
            ...
          }
        },
        _type == 'orderForm' => {
          ...,
          disclaimer[] {
            ${PORTABLE_TEXT_FRAGMENT}
          },
          formConfirmationText[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'resourceCards' => {
          ...,
          items[] {
            ...,
            resource {
              asset->{
                url
              }
            }
          }
        },
        _type == 'serviceSection' => {
          ...,
          items[] {
            ...,
            text[] {
              ${PORTABLE_TEXT_FRAGMENT}
            }
          }
        },
        _type == 'singleColText' => {
          ...,
          text[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'statisticCards' => {
          ...,
          text[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'stickyScroll' => {
          ...,
          items[] {
            ...,
            text[] {
              ${PORTABLE_TEXT_FRAGMENT}
            }
          }
        },
        _type == 'stripeOrderForm' => {
          ...,
          disclaimer[] {
            ${PORTABLE_TEXT_FRAGMENT}
          },
          formConfirmationText[] {
            ${PORTABLE_TEXT_FRAGMENT}
          }
        },
        _type == 'videoBlock' => {
          ...,
          video {
            asset->{
              mimeType,
              url
            }
          }  
        },
      }
    }
  }`;

  const [doc, setDoc] = useState();

  const handlePreviewFetch = useCallback(
    () =>
      sanityClient.fetch(queryDraft).then((response) => {
        setDoc(response[0]);
      }),
    [],
  );

  const { error, isResolved, isPending, isReloading, load } = useLoads(
    'handlePreviewFetch',
    handlePreviewFetch,
    {
      defer: true,
    },
  );

  useEffect(() => {
    load();
    const subscription = sanityClient.listen(queryDraft).subscribe((update) => {
      setDoc(update && update.result);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [0]);

  const renderPreview = () => {
    if (doc) {
      switch (doc._type) {
        case 'homePage':
          return <HomePage previewDoc={doc} preview={true} />;
        case 'modularPage':
          return <ModularTemplate previewDoc={doc} preview={true} />;
        case 'blogPost':
          return <PostTemplate previewDoc={doc} preview={true} />;
        default:
          break;
      }
    }
  };

  return (
    <>
      {(isPending || isReloading) && (
        <div className="ac">
          <span>Loading Preview</span>
        </div>
      )}
      {isResolved && !isPending && renderPreview()}
    </>
  );
};

const Preview = () => {
  return (
    <Router>
      <PreviewPage path="/preview/:document" />
    </Router>
  );
};

export default Preview;
